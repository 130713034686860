import React, { useState, useRef } from "react";
// import image2 from "../../../assets/home1bg/Layer5.svg";
// import AI from "../../../assets/image/AI.svg";
// import brand from "../../../assets/image/Brand.svg";
import styles from "./Section2.module.scss";
// import umbrellaIcon from "../../../assets/icons/TheUmbralla.svg";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";
import { Link } from "react-router-dom";

import marketAdvisory from "../../../assets/ourServices/marketAdvisory.webp";
import salesSupport from "../../../assets/ourServices/salesSupport.webp";
import buyerAssistance from "../../../assets/ourServices/buyerAssistance.webp";
import productFitment from "../../../assets/ourServices/productFitment.webp";
import goToMarket from "../../../assets/ourServices/go-to-market.webp";
import businessDevelopment from "../../../assets/ourServices/businessDevelopment.webp";

function Section2() {
  return (
    <div className={`${styles.sectionBackground} `}>
      <div className={styles.titleContainer + " pb-8"}>
        <span className={styles.iconWithText}>
          <FadeInOnScroll>
            <h1>Our</h1>
          </FadeInOnScroll>
        </span>
        &nbsp;
        <FadeInOnScroll>
          <span className={styles.ebitda}>Services</span>
        </FadeInOnScroll>
      </div>
      <div className="grid-cols-3 grid px-16 max-md:grid-cols-1 max-lg:grid-cols-2 max-lg:px-4 gap-6 ">
        {[
          {
            name: "Market Advisory:",
            img: marketAdvisory,
            tag: "Data-driven guidance on selecting the right micro-market and asset class.",
          },
          {
            name: "Businesss Development:",
            img: businessDevelopment,
            tag: "Strategies to enhance profitability, focusing on P&L management and capex utilization.",
          },
          {
            name: "Product Fitment:",
            img: productFitment,
            tag: "Design products based on market research, support with design and architectural needs.",
          },
          {
            name: "Go-to-Market Strategies:",
            img: goToMarket,
            tag: "Craft targeted campaigns based on buyer personas for market penetration.",
          },
          {
            name: "Sales Support:",
            img: salesSupport,
            tag: "Trained team excels in pitches, conversions, maximizing turnover margins.",
          },
          {
            name: "Buyer Assistance:",
            img: buyerAssistance,
            tag: "Streamline bank transactions and handover processes for a seamless experience.",
          },
        ].map((item, i) => {
          return (
            <div className="w-full text-white font-oxygen font-bold relative group h-72 max-md:h-72">
              <img
                src={item.img}
                className="w-full h-full object-cover rounded-xl"
                alt=""
              />
              <div className="absolute top-0 left-0 w-full h-full rounded-3xl bg-black/40" />
              <h4 className="absolute top-6 left-6 text-xl">{item.name}</h4>
              <p className="absolute top-16 left-6 text-base text-start capitalize font-thin w-2/3 opacity-0 group-hover:opacity-100 duration-200 max-lg:opacity-100">
                {item.tag}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Section2;
