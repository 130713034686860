import React from "react";
import styles from "./footer.module.scss";
import InstagramIcon from "../../assets/socialIcons/ig.png";
import LinkedIn from "../../assets/socialIcons/li.png";
import FacebookIcon from "../../assets/socialIcons/fb.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className={styles.footerSection}>
      <div
        className={
          styles.secondContainer +
          " h-100 py-10 space-y-10 sm:px-10 flex flex-col md:flex-row justify-around items-center"
        }
      >
        <div
          className={
            styles.leftPane + " w-auto space-y-6 text-center md:text-left "
          }
        >
          <h1 className="text-2xl text-white md:text-3xl lg:text-5xl xl:text-5xl font-semibold oxygen">
            CREDVEST
          </h1>
          <h1 className="text-3xl text-white md:text-4xl lg:text-5xl xl:text-5xl meddon">
            Know Good!
          </h1>
        </div>
        <div
          className={
            styles.middlePane + " w-auto space-y-6 text-center md:text-left"
          }
        >
          <div className="flex flex-col text-white space-y-2">
            <h1 className="text-lg font-semibold ">LEGAL</h1>
            <p>
              <Link to="/privacyPolicy">Privacy Policy</Link>
            </p>
            <p>Terms of Use</p>
            <p>Cookie Policy</p>
          </div>
          <div className="space-y-2">
            <div className="text-white text-lg font-semibold ">Social</div>
            <div className="flex justify-center md:justify-start flex-row space-x-6">
              <a
                href="https://www.facebook.com/CredvestMarketing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={FacebookIcon}
                  alt="Facebook Icon"
                  className="w-6 sm:w-8 md:w-6 lg:w-5 xl:w-5"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/credvest/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={LinkedIn}
                  alt="Attachment Icon"
                  className="w-6 sm:w-8 md:w-6 lg:w-5 xl:w-5"
                />
              </a>
              <a
                href="https://instagram.com/credvestgroup?igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={InstagramIcon}
                  alt="Instagram Icon"
                  className="w-6 sm:w-8 md:w-6 lg:w-5 xl:w-5"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.copyRight}>
        Copyright © 2022 Credvest.All Rights Reserved.
      </div>
    </div>
  );
}

export default Footer;
