import React, { useEffect } from "react";
import styles from "./Thankyou.module.scss";
import { useNavigate } from "react-router-dom";

function Thankyou() {
  const navigate = useNavigate();

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  const navigateToForm = () => {
    navigate(-1);
  };

  return (
    <div className={styles.ThankyouRoot}>
      <div className={styles.alignment}>
        <h3>
          Your request has been submitted. Our team will get back to you
          shortly.
        </h3>
        <button
          className={styles.popupButton + " button "}
          onClick={navigateToForm}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default Thankyou;
