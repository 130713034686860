import React from 'react'
import Section6 from '../../Home/Section6/Section6'

function Section5() {
  return (
    <div>
      <Section6 />
    </div>
  )
}

export default Section5