import React, { useState } from "react";
import styles from "./Section3.module.scss";
import FirstIcon from "../../../assets/Marketing/FirstIcon.svg";
import SecondIcon from "../../../assets/Marketing/SecondIcon.svg";
import ThirdIcon from "../../../assets/Marketing/ThirdIcon.svg";
import FourthIcon from "../../../assets/Marketing/FourthIcon.svg";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

const Section3 = () => {
  const topTabsContent = {
    Technology: {
      subTabs: [
        {
          title: "Data-Driven Analytics",
          content:
            "There is plenty of data available out there that can be gathered, segregated, and explained for market research and selling. ",
          icon: FirstIcon,
        },
        {
          title: "Salesforce",
          content:
            "A Customer Relationship Management (CRM) that harnesses the power of seamless customer experience and provides the means to turn leads into buyers and manage customer relationships. ",
          icon: SecondIcon,
        },
        {
          title: "Adobe Marketing Cloud",
          content:
            "The platform empowers us to manage, personalize, optimize, and orchestrate cross-channel campaigns for a faster and smarter way to reach the target audience. ",
          icon: ThirdIcon,
        },
        {
          title: "AI-Based Lead Generation",
          content:
            "Lead generation can be tricky when you are looking at getting Sales Quality Leads (SQLs) and this is where AI plays a pivotal role.",
          icon: FourthIcon,
        },
      ],
      rightPane: {
        header: "Technology",
        body: "Technology plays a vital role in modern real estate marketing. It enhances the property-buying experience, facilitates data-driven decision-making, optimizes online experiences, automates processes, and offers innovative tools to get ahead in the game. With the Credvest Tech Stack, you can reach a broader audience, and improve the overall selling experience for both sellers and buyers.",
      },
    },
    Visibility: {
      subTabs: [
        {
          title: "Traditional Digital Marketing ",
          content:
            "Google (SEM and SEO), Facebook (Organic and Paid), Websites,  Content Marketing",
          icon: FirstIcon,
        },
        {
          title: "Emerging Digital Frontiers",
          content:
            "Instagram Reels, YouTube Shorts, Web Stories, AI-Based Chatbots",
          icon: SecondIcon,
        },
        {
          title: "Offline Marketing",
          content:
            "Print Collaterals, Signages (Billboards, Banners, and more), Public Relations (PR), Events and Trade Shows",
          icon: ThirdIcon,
        },
      ],
      rightPane: {
        header: "Visibility",
        body: "Spreading across the marketing horizon helps with increased visibility, leading to an increase in sales. Online platforms offer extensive reach, visual appeal, and targeted marketing capabilities, while offline strategies allow for local connections, personalized interactions, and tangible marketing materials. A well-rounded marketing approach that combines both online and offline visibility maximizes your chances of reaching and engaging with potential buyers, leading to successful real estate sales.",
      },
    },
    Storytelling: {
      subTabs: [
        {
          title: "Building a connection",
          content: "A well-crafted narrative sets a property apart from the competition, making it memorable and desirable.",
          icon: FirstIcon,
        },
        {
          title: "Telling a relatable story",
          content: "Creating an emotional connection that extends beyond the physical aspects of the property can evoke feelings of comfort, excitement, and fulfillment, making a lasting impression on potential buyers.",
          icon: SecondIcon,
        },
        {
          title: "Building curiosity, context, and trust ",
          content: "By tapping into their desires and aspirations, storytelling helps potential buyers see the property as their future home.",
          icon: ThirdIcon,
        },
      ],
      rightPane: {
        header: "Storytelling  ",
        body: "Storytelling allows us to showcase the unique features, history, and potential of a property. It goes beyond just listing specifications and highlights the experiences and lifestyle that await its future owners. By weaving a compelling narrative, we can transform a mere structure into a place that ignites dreams and aspirations. Through the art of storytelling, we paint a vivid picture of how life could be within the walls of a home, evoking emotions and fostering a sense of belonging. Our team of skilled storytellers crafts narratives that inspire, engage, and compel potential buyers to embark on their own real estate journey.",
      },
    },
  };

  const [activeTopTab, setActiveTopTab] = useState("Technology");
  const [expandedTab, setExpandedTab] = useState(null);

  return (
    <div className={styles.parentContainer}>
      <div className={styles.tabs}>
        {Object.keys(topTabsContent).map((tabName) => (
          <button
            key={tabName}
            onClick={() => {
              setActiveTopTab(tabName);
              setExpandedTab(null);
            }}
            className={`${tabName === activeTopTab ? styles.activeTopTab : ""} ${styles.button}`}
            >
            {tabName}
          </button>
        ))}
      </div>
      <div className={styles.secondContainer}>
        <div className={styles.leftPane}>
          {topTabsContent[activeTopTab].subTabs.map((tab, index) => (
            <div key={index}>
              <button
                onClick={() =>
                  setExpandedTab(index === expandedTab ? null : index)
                }
                className={styles.tabButton}
              >
                <div className={styles.iconWithTitle}>
                  <img src={tab.icon} alt="Icon" className={styles.icon} />
                  <span>{tab.title}</span>
                </div>

                {index === expandedTab ? (
                  <i className="fa fa-minus" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-plus" aria-hidden="true"></i>
                )}
              </button>
              {index === expandedTab && (
                <div className={styles.content}>{tab.content}</div>
              )}
            </div>
          ))}
        </div>
        <div className={styles.rightPane}>
          <h1 className={styles.textHeader}>
            <FadeInOnScroll>{topTabsContent[activeTopTab].rightPane.header}</FadeInOnScroll>
          </h1>
          <p className={styles.textBody}>
           {topTabsContent[activeTopTab].rightPane.body}
          </p>
        </div>
      </div>
    </div>
  );
};
export default Section3;
