import React, { useState, useEffect, useRef } from "react";
import styles from "./Section3.module.scss";
import image1 from "../../../assets/knowGood/knowGoodSec3Bg.webp";
import chessImage from "../../../assets/caseStudies/chessPicture.jpg";
import caseStudiesVideoBg from "../../../assets/caseStudies/Cofeee.mp4";
import SearchIcon from "../../../assets/Branding/SearchIcon.svg";
import FinanceIcon from "../../../assets/sales/Section3/Finance.svg"
import FitmentIcon from "../../../assets/sales/Section3/Fitment.svg"
import UrgencyIcon from "../../../assets/sales/Section3/Urgency.svg"
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";



function Section3() {
  const tabsData = [
    {
      title: "Fitment Assessment",
      content: [
        { type: "text", value: "We have a proprietary client assessment system and every lead generated will go through an evaluation process. FItment refers to how close the client's needs are to the actual project and how we can help the client visualize the project to match the client's requirements." },
      ],
      icon: FitmentIcon,
    },
    {
      title: "Urgency Assessment",
      content: [
        { type: "text", value: "It is crucial to understand how fast a lead wants to close the deal, hence, we have an assessment it place to understand some of the pain points of our clients like:        " },
        { type: "bullet", value: "How long have they been in the market?        " },
        { type: "bullet", value: "Why haven't they found the properties?" },
        { type: "bullet", value: "Where is the delay?" },
        { type: "bullet", value: "Are they at an initial level or search or advanced level of search?" },
        

      ],
      icon: UrgencyIcon,
    },
    {
      title: "Finance and Cibil Assessment",
      content: [
        { type: "text", value: "Buying a house can get complicated especially when it comes to the budget or loan eligibility. At Credvest we try to make the house-buying journey much smoother and effortless by understanding the finances of our Client's and providing them with near-perfect solutions. Our experienced team of financial experts takes the time to sit down with each client individually, understanding their financial goals, constraints, and aspirations. Our mortgage specialists work closely with leading lenders to explore a wide range of loan options.  By optimizing our clients' eligibility, we help them gain a competitive edge in the real estate market and increase their chances of securing their dream home." },

      ],
      icon: FinanceIcon,
    },
    // ... other tab data
  ];

  const [activeTab, setActiveTab] = useState(0); // Default to first tab

  return (
    <div className={styles.parentContainer}>
      <video className={styles.backgroundVideo} autoPlay loop muted playsInline>
        <source src={caseStudiesVideoBg} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className={styles.secondContainer}>
        <FadeInOnScroll>
          <div className={styles.firstRowText}>
            <span className={styles.key}>Brand </span>
            <span className={styles.pageTitle}>Chapter</span>
          </div>
        </FadeInOnScroll>

        <div className={styles.paneContainer}>
          <div className={styles.left}>
            {tabsData.map((tab, index) => (
              <div
                key={index}
                className={
                  index === activeTab
                    ? `${styles.leftTabs} ${styles.activeTab}`
                    : styles.leftTabs
                }
                onClick={() => setActiveTab(index)}
              >
                {tab.title}
              </div>
            ))}
          </div>

          <div className={styles.right}>
            {/* <img src={tabsData[activeTab].icon} alt="icon" className="pb-6"/> */}

            <div className={styles.contentContainer}>
                {tabsData[activeTab].content.map((item, idx) => {
                  if (item.type === "text") {
                    return (
                      <p key={idx} className={styles.plainText}>
                        {item.value}
                      </p>
                    );
                  } else if (item.type === "bullet") {
                    return (
                      <ul key={idx} className={styles.contentList}>
                        <li className={styles.customBullet}>{item.value}</li>
                      </ul>
                    );
                  }
                  return null; // just in case there's an unexpected type
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;
