import Suman from "../../assets/about/suman.png";
import Siddarth from "../../assets/about/siddarth.png";
import Rajiv from "../../assets/about/rajiv.png";
import Sameer from "../../assets/about/sameer.png";
import Sourab from "../../assets/about/sourab.png";
import Sandeep from "../../assets/about/sandeep.png";
import Rafeek from "../../assets/about/rafeek.png";
import Karan from "../../assets/about/karan.png";
import Rishabh from "../../assets/about/rishabh.png";
import Aatira from "../../assets/about/aatira.png";
import Gaurav from "../../assets/about/gaurav.png";
import FadeInOnScroll from "../../components/fadeInOnScroll/FadeInOnScroll";
import styles from "./About.module.scss";
import Section6 from "../../AllSections/Home/Section6/Section6";
import { Fragment } from "react";

export default function About() {
  const data = [
    {
      name: "Siddharth Renganathan",
      image: Siddarth,
      title: "Chief Executive Officer",
      group: "Credvest Group",
      linkedin: "https://www.linkedin.com/in/siddharth-renganathan-88a1369/",
    },
    {
      name: "Rajiv John",
      image: Rajiv,
      title: "Chief Executive Officer",
      group: "AITI Interieurs",
      linkedin: "https://www.linkedin.com/in/johnrajiv/",
    },
    {
      name: "Sameer Feroz Bhayani",
      image: Sameer,
      title: "Chief Executive Officer",
      group: "Grahm",
      linkedin: "https://www.linkedin.com/in/sameer-bhayani-8a5183bb/",
    },
    {
      name: "Sourabh Kothari",
      image: Sourab,
      title: "Chief Business Officer",
      group: "Credvest Group",
      linkedin: "https://www.linkedin.com/in/sourabh-kothari-63b24717/",
    },
    //{
    //  name: "Sandeep Prabhudesai",
    //  image: Sandeep,
    //  title: "Chief Business Officer",
    //  group: "Parking Capital",
    //  linkedin: "https://www.linkedin.com/in/sandeep-prabhudesai-3872a54/",
    //},
    {
      name: "Rafeek N",
      image: Rafeek,
      title: "Chief Marketing Officer",
      group: "Credvest Group",
      linkedin: "https://www.linkedin.com/in/nicksaltr/",
    },
    {
      name: "Karan Anand",
      image: Karan,
      title: "Chief Finance Officer",
      group: "Credvest Group",
      linkedin: "https://www.linkedin.com/in/karan-anand-b93bb05a/",
    },
    {
      name: "Rishabh Sharma",
      image: Rishabh,
      title: "Director - Business Development",
      group: "Credvest Group",
      linkedin: "https://www.linkedin.com/in/rishabh-sharma-5518bb118/",
    },
    {
      name: "Gaurav Gupta",
      image: Gaurav,
      title: `Vice President & Head
Human Resources`,
      group: "Credvest Group",
      linkedin: "https://www.linkedin.com/in/gaurav-gupta-hr-leadership/",
    },
  ];
  return (
    <>
      <section className="pt-40">
        <div className="flex flex-row w-full justify-center px-10">
          <div className="flex flex-col md:flex-row text-3xl md:text-5xl font-bold">
            <FadeInOnScroll>
              About the
              <span className={styles.ebitda}>Credvest Group</span>
            </FadeInOnScroll>
          </div>
        </div>
        <p className="text-center text-black/50 mt-5 md:mt-7 text-lg px-5">
          Pioneering with vision and purpose–meet the minds driving our growth
        </p>

        <div className="flex flex-row w-full justify-center px-5 mt-32">
          <a
            href="https://www.linkedin.com/in/suman-naidu-555bb929/"
            target="_blank"
            className=""
          >
            <img src={Suman} alt="" className="rounded-full w-60" />
            <h4 className="mt-3 font-semibold text-xl">Suman Naidu</h4>
            <p className="text-black/50">Chairman &amp; Mentor</p>
            <p className="-mt-1 text-black/50">Credvest Group</p>
          </a>
        </div>
        <div className="flex flex-row flex-wrap mt-20 gap-y-10 md:gap-y-16 justify-center px-5 md:gap-x-[5%] gap-x-[2%]">
          {data.map(({ linkedin, image, name, title, group }) => (
            <a href={linkedin} className="md:w-[15%] w-[45%]">
              <div className="justify-center flex">
                <img
                  src={image}
                  alt=""
                  className="rounded-full w-3/4 md:w-2/3"
                />
              </div>
              <h4 className="mt-3 font-semibold text-sm md:text-2xl">{name}</h4>
              <p className="text-black/50 max-md:text-xs">
                {title.split("\n").map((x) => (
                  <Fragment key={x}>
                    {x}
                    <br />
                  </Fragment>
                ))}
              </p>
              <p className="-mt-1 text-black/50 max-md:text-xs">{group}</p>
            </a>
          ))}
        </div>
        <div className="text-center mb-10 mt-20">
          <a
            href="/contactUs"
            className="text-primary md:text-xl text-sm italic underline font-semibold"
            style={{ fontFamily: "Source Sans Pro" }}
          >
            Explore Opportunities
          </a>
        </div>

        <Section6 />
      </section>
    </>
  );
}
