import React from "react";
import image from "./HomeSec1RightImage.webp";

const HomeSec1RightBg = ({ imageSrc }) => {
  return (
    <svg
      viewBox="0 0 1006 991"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className=""
    >
      <g id="Mask group">
        <mask
          id="mask0_1307_3050"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1006"
          height="991"
        >
          <rect id="Rectangle 1105" width="1006" height="991" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1307_3050)">
          <g id="Group 114">
            <g id="Mask group_2">
              <mask
                id="mask1_1307_3050"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="61"
                y="81"
                width="871"
                height="903"
              >
                <path
                  id="Rectangle 1066"
                  d="M115.797 718.112C4.34744 525.076 70.4863 278.242 263.522 166.793L323.123 132.382C503.326 28.3417 733.752 90.0841 837.793 270.288L879.202 342.011C986.786 528.351 922.941 766.625 736.6 874.208L623.46 939.53C466.13 1030.36 264.954 976.459 174.119 819.129L115.797 718.112Z"
                  fill="url(#paint0_linear_1307_3050)"
                />
              </mask>
              <g mask="url(#mask1_1307_3050)">
                <g id="image 34">
                  <rect
                    x="-223"
                    y="66"
                    width="1440"
                    height="912"
                    fill="url(#pattern0)"
                  />
                  <rect
                    x="-223"
                    y="66"
                    width="1440"
                    height="912"
                    fill="url(#paint1_linear_1307_3050)"
                    style={{ mixBlendMode: "multiply" }}
                  />
                </g>
                <g id="Homepage">
                  <rect
                    x="-113"
                    y="89.9995"
                    width="1359"
                    height="905"
                    fill="url(#pattern1)"
                  />
                  <rect
                    x="-113"
                    y="89.9995"
                    width="1359"
                    height="905"
                    fill="url(#paint2_linear_1307_3050)"
                    style={{ mixBlendMode: "multiply" }}
                  />
                </g>
              </g>
            </g>
            <g id="Subtract" filter="url(#filter0_dd_1307_3050)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M238.241 112.821C36.3214 229.399 -32.8615 487.594 83.7171 689.514L144.724 795.181C239.739 959.752 450.175 1016.14 614.745 921.123L733.093 852.795C928.01 740.259 994.793 491.02 882.258 296.103L838.943 221.079C730.114 32.5815 489.083 -32.0026 300.585 76.8266L238.241 112.821ZM257.708 172.539C72.3686 279.545 8.86651 516.538 115.872 701.878L171.87 798.868C259.083 949.925 452.239 1001.68 603.296 914.468L711.925 851.751C890.837 748.457 952.137 519.683 848.842 340.772L809.084 271.908C709.191 98.889 487.952 39.6082 314.933 139.501L257.708 172.539Z"
                fill="url(#paint3_linear_1307_3050)"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_1307_3050"
            transform="scale(0.00166667 0.00263158)"
          />
        </pattern>
        <pattern
          id="pattern1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image1_1307_3050"
            transform="matrix(0.000244141 0 0 0.000366616 0 -0.000430281)"
          />
        </pattern>
        <filter
          id="filter0_dd_1307_3050"
          x="-36.9133"
          y="-48.0393"
          width="1053.84"
          height="1087.32"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="8" />
          <feGaussianBlur stdDeviation="36" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1307_3050"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="12" dy="4" />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1307_3050"
            result="effect2_dropShadow_1307_3050"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1307_3050"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1307_3050"
          x1="281.705"
          y1="156.295"
          x2="429.554"
          y2="966.086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#052247" />
          <stop offset="0.531361" stop-color="#9055A2" />
          <stop offset="1" stop-color="#FF4A1C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1307_3050"
          x1="497"
          y1="66"
          x2="497"
          y2="978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF4A1C" />
          <stop offset="1" stop-color="#9055A2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1307_3050"
          x1="566.5"
          y1="89.9995"
          x2="566.5"
          y2="995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF4A1C" />
          <stop offset="1" stop-color="#9055A2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1307_3050"
          x1="257.256"
          y1="81.7024"
          x2="257.256"
          y2="951.172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#052247" />
          <stop offset="0.177452" stop-color="#9055A2" />
          <stop offset="0.60412" stop-color="#FF4A1C" />
        </linearGradient>
        <image
          id="image0_1307_3050"
          data-name="image.png"
          width="600"
          height="380"
          xlinkHref={imageSrc}
        />
      </defs>
    </svg>
  );
};

export default HomeSec1RightBg;
