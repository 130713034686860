import React, { useState } from "react";
import styles from "./Section6.module.scss";
import arrowRight from "../../../assets/icons/arrowRight.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";

function Section6() {
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = {
      name: e.target.name.value,
      phone: e.target.phone.value,
      email: e.target.email.value,
      referral_source: e.target.referSelect.value,
      partnership_reason: e.target.partnerField.value,
    };

    emailjs
      .send(
        "credvest_official",
        "template_jm5yawf",
        formData,
        "q0nRvvwDl5D-22Y-F"
      )
      .then((result) => {
        console.log("Success:", result);
        // setShowPopup(true);
        navigate("/thankyou");

        // document.body.style.overflow = "hidden";
        e.target.reset();
        setIsLoading(false);
      })

      .catch((error) => {
        console.error("Error:", error);
        alert(
          "An error occurred while submitting the form. Please try again later."
        );
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.homeSection}>
      <div className={styles.formContainer}>
        <h1 className={styles.pageTitle}>
          We'd love to know how you discovered us!
        </h1>

        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.firstRow}>
            <div className={styles.selectWrapper}>
              <select
                name="referSelect"
                className={styles.referSelect}
                defaultValue=""
              >
                <option disabled value="">
                  Tell us who referred you?
                </option>
                <option value="Google">Google</option>
                <option value="Social Media">Social Media </option>
                <option value="Existing Partner">Existing Partner </option>
              </select>
              <FontAwesomeIcon
                icon={faAngleDown}
                className={styles.selectIcon}
              />
            </div>

            <input
              name="partnerField"
              className={styles.partnerField}
              type="text"
              placeholder="Why do you want to partner with us?"
            />
          </div>

          <div className={styles.suggestionText}>
            <p className={styles.firstSuggestion}>
              Haven’t been referred? No problem
            </p>
            <p className={styles.secondSuggestion}>
              Fill in your details and we will get back to you real soon!
            </p>
          </div>

          <h2
            className={styles.contactTitle + " text-start"}
            style={{ width: "100%" }}
          >
            How do we contact you?
          </h2>

          <div className={styles.contactFieldContainer}>
            <input
              name="name"
              className={styles.name}
              type="text"
              placeholder="Name*"
              required
            />
            <input
              name="phone"
              className={styles.phone}
              type="tel"
              placeholder="Phone*"
              required
            />
            <input
              name="email"
              className={styles.email}
              type="email"
              placeholder="Email"
            />
          </div>

          <div className={styles.lastRowContainer}>
            {/* <p className={styles.congratsText}>
              Congratulations on making the right decision! Submit now and..
            </p> */}
            <div className={styles.buttonContainer}>
              <button
                type="submit"
                className={styles.buttonText + " button "}
                disabled={isLoading}
              >
                {isLoading ? (
                  "Sending..."
                ) : (
                  <>
                    Let's get started
                    <img
                      src={arrowRight}
                      alt="Client Icon"
                      className={styles.icon}
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>

      {showPopup && (
        <div className={styles.popupOverlay}>
          <div className={styles.popupContent}>
            <h3 className={styles.thankyouText}>Thank You!</h3>
            <p>
              Your request has been submitted. Our team will get back to you
              soon.
            </p>
            <button
              className={styles.popupButton + " button "}
              onClick={() => {
                setShowPopup(false);
                document.body.style.overflow = "auto"; // <-- Add this line
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Section6;
