import React, { useEffect } from "react";
import Section6 from "../../AllSections/Home/Section6/Section6";
import TopBG from '../../assets/services/topBG.webp'
import BuyerAss from '../../assets/services/buyerAss.webp'
import styles from './styles.module.css'
import arrowRight from "../../assets/icons/arrowRight.svg";
import GoTo from "../../assets/services/goTo.webp"
import EndToEnd from "../../assets/services/endToEnd.webp"
import Market from "../../assets/services/market.webp"
import Partnership from "../../assets/services/partnership.webp"

export default function Services() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <>
      <div
        className="relative h-screen bg-cover bg-center text-white"
        style={{ backgroundImage: "url('/s2.png')" }}
      >
        <div className="px-5 pt-14 md:pt-20 flex justify-center bg-center bg-no-repeat max-md:bg-cover" style={{ backgroundImage: `url('${TopBG}')` }}>
          <div className="md:w-[76%] py-[8%] md:py-[2%] w-[90%]">
            <h1 className="font-semibold mb-2 text-start text-white relative z-[9999999999999999] text-xl md:text-4xl">
              Services |&nbsp;
              <br className="md:hidden" />
              <span className="text-white font-light text-lg md:text-3xl">Maximizing Real Estate Success</span>
            </h1></div>
        </div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full">
          <div className="text-left w-full max-w-4xl max-md:px-5">
            <div className="text-center">
              <h2 className="text-xl md:text-4xl font-bold mb-6 font-oxygen">
                Strategic Planning for Real Estate Excellence
              </h2>
              <h3 className="text-3xl md:text-5xl mb-[50%] md:mb-[10%] font-oxygen font-bold text-white">
                The&nbsp;
                <span className={styles.gradient}>
                  EBITDA
                </span>
                &nbsp;Engine
              </h3>
            </div>
          </div>

        </div>
      </div>
      <div className="relative">
        <img className="absolute -left-20 top-0" src="/s3.png" alt="" />
      </div>
      <div className="flex flex-col items-center justify-center min-h-screen bg-white text-gray-900 p-6">
        <div className="text-center font-oxygen max-md:mt-10">
          <h1 className={"text-xl md:text-2xl lg:text-4xl font-bold mb-4 " + styles.gayColor}>
            <span className="text-[#151515]">Market Advisory: </span>
            <span>Informed Decisions,</span><br />
            <span> Superior Outcomes</span>
          </h1>
        </div>

        <div className="bg-white p-6 rounded-lg mt-8 max-w-7xl w-full relative z-20">
          <div className="flex flex-col justify-start">
            <h2 className="bg-primary w-fit text-white py-3 px-8 rounded-xl inline-block text-xs md:text-xl font-bold">
              Key Highlights
            </h2>
          </div>

          <div className="flex flex-col  md:flex-row items-center md:gap-x-20 justify-between mt-10">
            <div className="flex-1 md:mr-8">
              <div className="text-left">
                <ul className="text-left font-bold font-oxygen text-[#071013] space-y-5 md:space-y-10 max-md:text-sm">
                  <li className="bg-white px-5 py-2 border-2 rounded-xl">
                    Deep analysis of micro-markets and rental trends.
                  </li>
                  <li className="bg-white px-5 py-2 border-2 rounded-xl">
                    Strategic insights into property options and real estate
                    partnerships.
                  </li>
                  <li className="bg-white px-5 py-2 border-2 rounded-xl">
                    Tailored market entry strategies.</li>
                </ul>
              </div>
            </div>

            <div className="flex-1 mt-8 md:mt-0">
              <p className="text-lg max-md:text-sm font-oxygen text-start">
                Credvest offers data-driven guidance on selecting the right
                micro-market and asset class to align with market demands. Our
                expert analysis mitigates risks, ensuring developers make
                informed decisions that position their projects for success.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-10 md:mt-60 italic text-[#071013] text-sm md:text-xl font-semibold">
          Learn More About Our Market Advisory Services
        </div>
        <div className="mt-8">
          <a href="/contactUs"
            style={{ fontFamily: "Source Sans Pro" }}
            className="flex flex-row gap-x-3 font-semibold bg-primary w-fit px-5 py-3 rounded-xl text-white"
          >
            Contact Us
            <img src={arrowRight} alt="Client Icon" />
          </a>
        </div>
      </div>
      <div
        className="flex flex-col font-oxygen items-center justify-center min-h-screen bg-cover bg-center text-white p-6"
        style={{ backgroundImage: "url('/s4.png')" }}
      >
        <div className="text-center font-oxygen">
          <h1 className="text-xl max-md:mt-10 md:text-2xl lg:text-4xl font-bold mb-4">
            <span>Business Development: </span><br />
            <span className="text-primary">
              Enhancing Profitability and Growth
            </span>
          </h1>
        </div>

        <div className="p-6 rounded-lg mt-4 md:mt-8 max-w-7xl w-full">
          <p className="text-sm md:text-lg max-w-4xl font-oxygen text-center mb-8 mx-auto">
            Credvest offers data-driven guidance on selecting the right
            micro-market and asset class to align with market demands. Our
            expert analysis mitigates risks, ensuring developers make informed
            decisions that position their projects for success.
          </p>

          <div className="text-center">
            <h2 className="text-xl md:text-3xl font-bold mb-6">Key Highlights</h2>
            <div className="grid grid-cols-1 max-md:text-sm md:grid-cols-3 gap-6">
              <div className="bg-[#faebe7] font-bold text-black py-8 px-14 rounded-xl shadow-md grid place-items-center">
                Professional sales training.
              </div>
              <div className="bg-[#faebe7] text-black py-8 px-14 rounded-xl shadow-md font-bold grid place-items-center">
                High-conversion pitch strategies.
              </div>
              <div className="bg-[#faebe7] text-black py-8 px-14 rounded-xl shadow-md font-bold grid place-items-center">
                Timeline adherence and turnover maximization.
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10 md:mt-80">
          <a
            href="/contactUs"
            style={{ fontFamily: 'Source Sans Pro' }}
            className="text-primary italic text-sm md:text-lg underline font-bold hover:text-orange-600"
          >
            Learn More About Our Market Advisory Services
          </a>
        </div>
      </div>
      <div className="bg-gray-100 min-h-screen flex flex-col justify-center font-oxygen p-8">
        <h2 className="text-xl md:text-2xl lg:text-4xl max-md:pt-10 font-bold text-center">
          Product Development:<br />
          <span className={styles.gayColor}>
            Crafting Market-Ready Real Estate Solutions
          </span>
        </h2>
        <p className="text-sm md:text-xl text-black mt-10 max-w-6xl mx-auto">
          Credvest designs real estate products based on extensive market
          research. We identify reliable construction partners and provide
          end-to-end support throughout the development process, ensuring that
          every project meets market demands and exceeds expectations.
        </p>
        <h4 className="text-xl md:text-4xl font-bold mt-20">Key Highlights</h4>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 gap-y-5 md:grid-cols-3 place-items-center mt-5 md:mt-20 pb-5 md:pb-20 w-5/6 border-b border-b-black/30">
            <div className="grid place-items-center">
              <img src={Market} alt="" className="max-md:w-1/2" />
              <p className="font-bold md:text-xl text-sm mt-5">Market research-driven<br />
                product design.</p>
            </div>
            <div className="grid place-items-center">
              <img src={Partnership} alt="" className="max-md:w-1/2" />
              <p className="font-bold md:text-xl text-sm mt-5">
                Partnerships with<br />
                trusted construction firms.</p>
            </div>
            <div className="grid place-items-center">
              <img src={Market} alt="" className="max-md:w-1/2" />
              <p className="font-bold md:text-xl text-sm mt-5">
                End-to-end development<br />
                support.
              </p>
            </div>
          </div>
        </div>

        <div className="text-center mt-20">
          <a href="/contactUs" className="text-primary md:text-xl text-sm italic underline font-semibold" style={{ fontFamily: 'Source Sans Pro' }}>
            Explore Our Product Development Services
          </a>
        </div>
      </div>

      <div className="bg-white min-h-screen font-oxygen flex flex-col justify-center text-black p-8">
        <h2 className="text-xl md:text-2xl lg:text-4xl font-bold mb-4">
          Go-to-Market Strategies:{" "}<br />
          <span className={styles.gayColor}>
            Targeted Campaigns for Maximum Impact
          </span>
        </h2>

        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center mt-20">
          <div className="md:w-1/2 flex flex-col justify-between gap-y-10">
            <p className="mb-4 md:text-start text-sm md:text-xl">
              Our go-to-market strategies are designed to achieve effective
              market penetration and engagement. By tailoring targeted campaigns
              across various delivery channels, we ensure maximum reach and
              impact.
            </p>
            <div className="">
              <h5 className="font-bold mt-10 text-start text-xl md:text-3xl">Key Highlights</h5>
              <ul className="text-start list-disc list-inside text-sm font-bold mt-8 space-y-1 md:text-lg">
                <li>Creation of localized buyer personas.</li>
                <li>Precision-driven marketing strategies.</li>
                <li>Effective digital and offline campaigns.</li>
              </ul>
            </div>
            <div className="w-full flex flex-row justify-start">
              <a href="/contactUs" style={{ fontFamily: 'Source Sans Pro' }} className="text-start w-full text-primary italic font-bold underline text-sm md:text-xl">
                See More on Go-to-Market Strategies

              </a>
            </div>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 md:ml-8">
            <img
              src={GoTo}
              alt="Marketing Strategy"
              className="w-full h-auto"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: "url(/s6.png)",
        }}
        className="bg-no-repeat flex flex-col justify-center bg-cover min-h-screen text-white py-16 font-oxygen"
      >
        <div className="max-w-6xl mx-auto text-center max-md:px-5">
          <h2 className="text-xl md:text-2xl lg:text-4xl font-bold mb-4">
            Sales Support:{" "}<br />
            <span className="text-primary">
              Converting Leads into Success
            </span>
          </h2>
          <div className="md:mt-20 md:mb-10 my-5">
            <h3 style={{ fontFamily: 'Source Sans Pro' }} className="font-bold text-sm md:text-xl mb-2 bg-primary px-5 md:px-10 py-2.5 md:py-5 rounded-xl w-fit">Key Highlights</h3>
          </div>

          <div className="text-left flex flex-col md:flex-row items-center md:gap-x-40 justify-center gap-8">

            <div className="md:w-1/2">
              <div className="text-[#071013] max-md:text-sm p-4 rounded-lg shadow-md">
                <ul className="space-y-4">
                  <li className="bg-white md:px-10 px-4 md:py-5 py-2.5 font-bold rounded-xl">Professional sales training.</li>
                  <li className="bg-white md:px-10 px-4 md:py-5 py-2.5 font-bold rounded-xl">High-conversion pitch messages.</li>
                  <li className="bg-white md:px-10 px-4 md:py-5 py-2.5 font-bold rounded-xl">Tailored client interaction and resources.</li>
                </ul>
              </div>
            </div>
            <div className="md:w-1/2">
              <p className="mb-4 text-sm md:text-xl max-md:text-center">

                Credvest provides trained sales teams that excel in delivering compelling pitches, managing conversions, and adhering to timelines. Our approach maximizes turnover margins and ensures that sales targets are consistently met.
              </p>
            </div>
          </div>
          <a href="/contactUs" className="font-bold italic underline mt-20 text-sm md:text-xl text-white" style={{
            fontFamily: "Source Sans Pro"
          }}>Boost Your Sales with Our Expertise</a>

        </div>
      </div>
      <div style={{ backgroundImage: `url('${BuyerAss}')` }} className="bg-white pt-20 min-h-screen text-black p-8 font-oxygen">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-xl md:text-2xl lg:text-4xl font-bold mb-4">
            Buyer Assistance:{" "}<br />
            <span className={styles.startFinish}>
              A Seamless Experience from Start to Finish
            </span>
          </h2>
          <p className="mb-4 text-sm mt-5 md:text-xl max-w-5xl mx-auto">
            We streamline bank transactions and handover processes, offering
            buyers a seamless and hassle-free experience. Credvest ensures that
            every step of the buying process is smooth and efficient, fostering
            trust and long-term relationships with clients.
          </p>
          <h3 className="text-xl md:text-3xl font-bold mt-10 mb-8">Key Highlights:</h3>
          <div className="flex mt-10 flex-col md:flex-row max-md:items-center justify-center gap-8">

            <img src="/s8.png" className="max-md:w-2/3" alt="" />
            <img src="/s9.png" className="max-md:w-2/3" alt="" />
            <img src="/s10.png" className="max-md:w-2/3" alt="" />
          </div>
          <a
            href="/contactUs"
            style={{ fontFamily: 'Source Sans Pro' }}
            className="text-sm md:text-xl font-bold underline text-black mt-20 inline-block italic"
          >
            Learn More About Our Buyer Assistance Services
          </a>
        </div>
      </div>
      <Section6></Section6>
    </>
  );
}
