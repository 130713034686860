import React from "react";
import styles from "./Section1.module.scss";
import arrowRight from "../../../assets/icons/arrowRight.svg";
import HomeBG from "../../../assets/home/backwithBlob.webp";
import HomeSec1RightBg from "../../../assets/homepage/HomeSec1RightBg";
import HomeSec1RightImage from "../../../assets/homepage/Section1/Homepagesec1.jpg";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section1({ scrollToSection6 }) {
  return (
    <div className="pt-20 relative flex flex-row-reverse h-screen overflow-x-clip items-start justify-center max-md:flex-col">
      <div className="md:w-[60vw] z-40 md:z-40 max-md:h-[50vh] max-md:scale-125 top-10 max-md:left-0 absolute md:top-0 md:-right-[10%] overflow-x-clip max-md:w-full max-md:-translate-x-[5%]">
        <img src={HomeBG} className="object-cover w-full h-full" />
      </div>
      <div className="flex z-30 relative flex-col md:w-full max-md:pt-[40vh] md:pl-[10%] justify-center items-center md:items-start h-[80vh] max-md:h-full max-lg:px-6">
        <h1 className="text-3xl md:text-4xl font-bold text-gray-800">
          Driving Real Estate Success
        </h1>
        <p className="text-xl text-primary mt-2">
          Strategic Guidance, Market Insights, and Exceptional Results
        </p>
        <div className="mt-3 md:mt-6 space-y-0.5 md:space-y-2 max-md:grid max-md:place-items-center max-md:w-full md:text-start ">
          <div className="md:text-3xl text-2xl font-bold text-primary">6.5 Mn SqFt</div>
          <div className="text-gray-600">Real Estate Managed</div>
          <div className="md:text-3xl text-2xl font-bold text-primary">~4000</div>
          <div className="text-gray-600">Customers Trust Us</div>
          <div className="md:text-3xl text-2xl font-bold text-primary">₹ 4,800 Cr</div>
          <div className="text-gray-600">Transaction Managed</div>
        </div>
        <div className="max-md:grid max-md:w-full max-md:place-items-center mt-5 md:mt-8 max-md:mb-8">
          <a href="/contactUs" className="bg-primary text-white py-2 px-4 rounded hover:bg-orange-600">
            Get in Touch &rarr;
          </a>
        </div>
      </div>
    </div>
  );
}

export default Section1;
