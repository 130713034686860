import React from "react";

const knowGoodSec2bg = ({ imageSrc }) => {
  return (
    <svg viewBox="0 0 1008 1034" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Mask group">
        <mask
          id="mask0_1052_4724"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1008"
          height="1034"
        >
          <rect id="Rectangle 1110" width="1008" height="1034" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1052_4724)">
          <g id="Group 191">
            <path
              id="Rectangle 1066"
              d="M490.4 43.3079C682.321 -8.11715 880.318 105.583 931.743 297.503L959.278 400.264C1025.04 645.68 879.396 897.938 633.98 963.697L533.085 990.732C340.133 1042.43 141.803 927.927 90.1021 734.976C9.41218 433.837 189.261 123.998 490.4 43.3079Z"
              fill="#E9DDEC"
            />
            <g id="Group 124">
              <g id="Group 116">
                <path
                  id="Rectangle 1058"
                  d="M608.608 323.511C724.842 292.366 844.756 361.226 875.901 477.46L892.577 539.696C932.403 688.329 844.198 841.105 695.565 880.931L634.459 897.304C517.601 928.617 397.485 859.268 366.173 742.409C317.304 560.029 426.227 372.379 608.608 323.511Z"
                  fill="url(#paint0_linear_1052_4724)"
                />
                <g id="Mask group_2">
                  <mask
                    id="mask1_1052_4724"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="355"
                    y="316"
                    width="548"
                    height="589"
                  >
                    <path
                      id="Rectangle 1058_2"
                      d="M609.463 323.511C725.698 292.366 845.612 361.226 876.756 477.46L893.432 539.696C933.259 688.329 845.053 841.105 696.42 880.931L635.315 897.304C518.457 928.617 398.341 859.268 367.029 742.409C318.16 560.029 427.083 372.379 609.463 323.511Z"
                      fill="url(#paint1_linear_1052_4724)"
                    />
                  </mask>
                  <g mask="url(#mask1_1052_4724)">
                    <g id="Group 107">
                      <rect
                        id="image 37"
                        x="-85.8179"
                        y="318"
                        width="1422"
                        height="949"
                        fill="url(#pattern0)"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <path
                id="Subtract"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M602.615 284.974C724.702 252.261 850.655 324.59 883.368 446.677L900.884 512.046C942.715 668.164 850.068 828.633 693.951 870.465L629.768 887.662C507.025 920.551 380.861 847.71 347.972 724.967C296.643 533.403 411.051 336.304 602.615 284.974ZM573.744 888.743C592.005 888.779 610.571 886.434 629.054 881.482L688.073 865.668C831.63 827.202 916.823 679.643 878.357 536.086L862.25 475.976C837.025 381.836 751.512 319.87 658.361 320.053C640.161 320.017 621.663 322.352 603.26 327.283C427.108 374.483 321.905 555.724 369.105 731.876C394.47 826.54 480.169 888.924 573.744 888.743Z"
                fill="url(#paint2_linear_1052_4724)"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_1052_4724"
            transform="matrix(0.0025641 0 0 0.00385388 0 -0.0395428)"
          />
        </pattern>
        <linearGradient
          id="paint0_linear_1052_4724"
          x1="892.098"
          y1="537.91"
          x2="350.279"
          y2="683.09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9055A2" />
          <stop offset="1" stop-color="#FF4A1C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1052_4724"
          x1="892.954"
          y1="537.91"
          x2="351.134"
          y2="683.09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9055A2" />
          <stop offset="1" stop-color="#FF4A1C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1052_4724"
          x1="733.679"
          y1="277"
          x2="388.678"
          y2="854.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#052247" />
          <stop offset="0.82293" stop-color="#9055A2" />
          <stop offset="1" stop-color="#FF4A1C" />
        </linearGradient>
        <image 
    id="image0_1052_4724" 
    data-name="image.png" 
    preserveAspectRatio="xMidYMid slice"
    x="96"
    y="10"
    width="200"
    height="162"
    xlinkHref={imageSrc} 
/>
      </defs>
    </svg>
  );
};

export default knowGoodSec2bg;
