import React, { useEffect } from "react";
import styles from "./contactUs.module.scss";
import Section1 from "../../AllSections/ContactUs/Section1/Section1";
import Section2 from "../../AllSections/ContactUs/Section2/Section2";
import Section3 from "../../AllSections/ContactUs/Section3/Section3";
import Section4 from "../../AllSections/ContactUs/Section4/Section4";

function ContactUs() {
  useEffect(() => {
    window.scrollTo({ top: 0, 'behavior': 'smooth' })
  }, []);
  return (
    <div>
      <Section1 />
      <Section2 />
      <Section4 />
      <Section3 />
    </div>
  );
}

export default ContactUs;
