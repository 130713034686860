import React from 'react';

const CultureSec3MaskBg = ({ videoSrc }) => {
     const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const videoContent = isSafari ? (
        <image href={videoSrc} width="100%" height="100%" clipPath="url(#videoClip)" />
    ) : (
        <foreignObject x="0" y="0" width="1672" height="957" clipPath="url(#videoClip)">
            <video width="100%" height="100%" autoPlay muted loop>
                <source src={videoSrc} type="video/mp4" />
            </video>
        </foreignObject>
    );
    return (
        <svg viewBox="0 0 1672 957" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <clipPath id="videoClip">
                    <path d="M994.217 96C1367.99 96 1671 237.991 1671 413.145L1671 500.778C1671 709.688 1309.6 879.043 863.79 879.043L738.154 879.043C330.483 879.043 -2.92453e-05 724.176 -2.03514e-05 533.138C-9.11163e-06 291.714 417.649 95.9999 932.846 96L994.217 96Z" />
                </clipPath>

                <linearGradient id="paint0_linear_1173_5883" x1="1614.52" y1="621.919" x2="694.537" y2="-307.299" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#052247" />
                    <stop offset="0.510417" stop-color="#9055A2" />
                    <stop offset="1" stop-color="#FF4A1C" />
                </linearGradient>
            </defs>

            <g id="CultureSec3MaskBg">
                <path id="BackgroundLayer" d="M994.217 0.411099C1367.99 0.411118 1671 173.818 1671 387.727L1671 494.75C1671 749.883 1309.6 956.709 863.79 956.709L738.154 956.709C330.483 956.708 -2.92453e-05 767.576 -2.03514e-05 534.27C-9.11163e-06 239.428 417.649 0.41107 932.846 0.411096L994.217 0.411099Z" fill="#D2CCD4" />
                {videoContent}

                <path id="OverlayLayer" d="M995.217 96C1368.99 96 1672 237.991 1672 413.145L1672 500.778C1672 709.688 1310.6 879.043 864.79 879.043L739.154 879.043C331.483 879.043 0.999971 724.176 0.99998 533.138C0.999991 291.714 418.649 95.9999 933.846 96L995.217 96Z" fill="url(#paint0_linear_1173_5883)" fill-opacity="0.7" />
            </g>
        </svg>
    );
}

export default CultureSec3MaskBg;
