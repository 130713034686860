import React from "react";
import styles from "./Section2.module.scss";
import CaseStudiesSec2Bg from "../../../assets/caseStudies/CaseStudiesSec2Bg";
import CaseStudiesSec2img from "../../../assets/caseStudies/CaseStudiesSec2img.webp";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section2() {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.absoluteImage}>
        <CaseStudiesSec2Bg imageSrc={CaseStudiesSec2img} />
      </div>
      <div className={styles.secondContainer}>
        <h1 className={styles.pageTitle}> Success-fueling thoughts </h1>
        <p className={styles.textBody}>
          Success begins with the thoughts you cultivate—the positive,
          empowering beliefs that ignite the flames of achievement. Remember,
          success starts with a single thought. Let's make it a powerful one.
        </p>
      </div>
    </div>
  );
}

export default Section2;
