import React from "react";
import styles from "./Section4.module.scss";
import Arrow from "../../../assets/knowGood/icons/RightArrowStick.svg";
import { useNavigate } from 'react-router-dom';
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section4() {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate('/casestudies', { state: { fromSection5: true } });
  };

  return (
    <div className={styles.parentContainer}>
      <div className={styles.secondContainer}>
        
        <div
          className={styles.circle}
          onClick={handleNavigation}
        >
          <p className={styles.text}> 70% Villa inventory sold in 6 months </p>
          <img className={styles.arrow} src={Arrow} alt="arrow" />
        </div>

        <div
          className={styles.circle}
          onClick={handleNavigation}
        >
          <p className={styles.text}>
             25% apartment Inventory sold in under 2 months 
          </p>
          <img className={styles.arrow} src={Arrow} alt="arrow" />
        </div>

        <div
          className={styles.circle}
          onClick={handleNavigation}
        >
          <p className={styles.text}>
             100% Apartment Inventory sold in under 6 months 
          </p>
          <img className={styles.arrow} src={Arrow} alt="arrow" />
        </div>
      </div>
    </div>
  );
}

export default Section4;
