import React, { useState } from "react";
import styles from "./Section4.module.scss";
import arrowRight from "../../../assets/icons/arrowRight.svg";
import contactPic from "../../../assets/ContactUs/contactImg.png";
import contactUsBG from "../../../assets/ContactUs/contactUsBG.png";
import emailjs from "emailjs-com";
import { Navigate, useNavigate } from "react-router-dom";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section4() {
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = {
      name: e.target.name.value,
      phone: e.target.phone.value,
      email: e.target.email.value,
      message: e.target.message.value,
    };

    const templateId = "template_gdpoicq";

    emailjs
      .send("credvest_official", templateId, formData, "q0nRvvwDl5D-22Y-F")
      .then((result) => {
        setIsLoading(false);
        // setShowPopup(true);
        navigate("/thankyou");

        e.target.reset();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
        alert(
          "An error occurred while submitting the form. Please try again later."
        );
      });
  };

  return (
    <div
      style={{ backgroundImage: `url'(${contactUsBG})'` }}
      className={styles.parentContainer}
    >
      <FadeInOnScroll>
        <h1 className={styles.title}>
          Contact&nbsp;
          <span className={styles.gayColor}>Us</span>
        </h1>
      </FadeInOnScroll>
      <div
        className={`${styles.secondContainer} flex md:flex-row flex-col justify-center`}
      >
        <div className="md:w-1/2">
          <img
            src={contactPic}
            className="w-full h-full object-contain"
            alt=""
          />
        </div>
        <form
          className="md:w-1/2 flex flex-col justify-center"
          onSubmit={handleSubmit}
        >
          <div className={`${styles.fields} flex flex-col space-y-5 `}>
            <input
              name="name"
              type="text"
              placeholder="Name*"
              className={styles.NameInput}
              required
            />
            <input
              name="phone"
              type="tel"
              placeholder="Phone*"
              className={styles.PhoneInput}
              required
            />
            <input
              name="email"
              type="email"
              placeholder="Email"
              className={styles.EmailInput}
            />
            <textarea
              name="message"
              placeholder="Message"
              rows={4}
              className={styles.EmailInput}
            />
          </div>
          <div className={styles.lastRow}>
            <div className={styles.buttonContainer + " button"}>
              <button
                type="submit"
                className={styles.buttonText}
                disabled={isLoading}
              >
                {isLoading ? "Sending..." : "Submit"}
              </button>
              <img src={arrowRight} alt="Arrow Icon" className={styles.icon} />
            </div>
          </div>
        </form>
      </div>

      {showPopup && (
        <div className={styles.popupOverlay}>
          <div className={styles.popupContent}>
            <h3>Thank You!</h3>
            <p>
              Your request has been submitted. Our team will get back to you
              soon.
            </p>
            <button
              className={styles.popupButton + " button "}
              onClick={() => setShowPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Section4;
