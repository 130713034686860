import React, { useEffect, useRef } from 'react';
import styles from './DirectorCuts.module.scss';
import Section1 from '../../AllSections/Director/Section1/Section1'
import Section2 from '../../AllSections/Director/Section2/Section2'
import Section3 from '../../AllSections/Director/Section3/Section3'
import Section4 from '../../AllSections/Director/Section4/Section4'
import Section5 from '../../AllSections/Director/Section5/Section5'

function DirectorCuts() {
  const iframeRef = useRef(null);

  useEffect(() => {
    function handleResize(event) {
      const iframe = iframeRef.current;
      if (iframe && event.data.height) {
        iframe.style.height = `${event.data.height}px`;
      }
    }

    window.addEventListener('message', handleResize);
    return () => window.removeEventListener('message', handleResize);
  }, []);

  return (
    
    <div>
      <Section1 />
      <Section2 />
      {/* <Section3 /> */}
      <div className={styles.DirectorCuts}>
      <iframe
        ref={iframeRef}
        title="Director's Cut"
        src="https://directorscut.credvest.com/"
        className={styles.iframe}
        frameBorder="0"
      />
    </div>
      <Section4 />
      <Section5 />
    </div>
  );
}

export default DirectorCuts;
