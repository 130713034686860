import React, { useEffect, useRef, useState } from 'react';
import styles from './FadeInOnScroll.module.scss';

function FadeInOnScroll({ children }) {
    const fadeInRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleIntersection = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            });
        };

        const options = {
            threshold: [0.0, 0.1, 0.9, 1.0]  // Trigger the callback at 0%, 10%, 90%, and 100% visibility
        };
        

        const observer = new IntersectionObserver(handleIntersection, options);

        if (fadeInRef.current) {
            observer.observe(fadeInRef.current);
        }

        return () => {
            if (fadeInRef.current) {
                observer.unobserve(fadeInRef.current);
            }
        };
    }, []);

    return (
        <div className={`${styles.fade} ${isVisible ? styles['is-visible'] : ''}`} ref={fadeInRef}>
            {children}
        </div>
    );
}

export default FadeInOnScroll;
