import React, { useState, useEffect, useRef } from "react";
import styles from "./Section3.module.scss";
import image1 from "../../../assets/knowGood/knowGoodSec3Bg.webp";
import chessImage from "../../../assets/caseStudies/chessPicture.jpg";
import caseStudiesVideoBg from "../../../assets/caseStudies/Cofeee.mp4";
import SearchIcon from "../../../assets/Branding/SearchIcon.svg";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section3() {
  const tabsData = [
    {
      title: "Client Onboarding      ",
      content: "We understand that every client's journey begins with a personalized and efficient onboarding process. Our CRM platform facilitates a smooth transition by gathering essential client information, preferences, and requirements. From the very first interaction, we prioritize understanding client needs and aspirations, enabling us to tailor our services to client goals. Our intuitive client onboarding system ensures that the client's embarks on their real estate journey with confidence and clarity.",
      icon: SearchIcon // Replace with the corresponding icon for this tab
    },
    {
      title: "Loan Process Management and Disbursement",
      content: "Navigating the intricacies of loan processes in real estate can be overwhelming, but we simplify and streamline this crucial aspect for our clients. We prioritize convenience and financial stability, ensuring that the loan process becomes a hassle-free part of the client's real estate venture.",
      icon: SearchIcon // Replace with the corresponding icon for this tab
    },
    {
      title: "Customer Delight and Engagement",
      content: "We believe in fostering lasting relationships with our clients. . We keep our clients informed about the latest real estate trends, market insights, and property updates. Our personalized notifications and tailored recommendations ensure that our clients stay ahead in your investment journey. With our commitment to continuous engagement, we ensure that your experience with us transcends transactions and transforms into a valuable partnership.",
      icon: SearchIcon // And so on...
    },
    {
      title: "Cashflow Management",
      content: "Efficient cashflow management is pivotal for a successful real estate venture, and our CRM solutions are designed to empower you in this aspect. Our platform provides you with tools to monitor income and expenses associated with your properties. You can easily track rental payments, maintenance costs, and other financial aspects, helping you make informed decisions for optimizing your investments. Our goal is to empower you with the insights you need to manage your real estate portfolio's financial health effectively.",
      icon: SearchIcon // And so on...
    },
  ];

  const [activeTab, setActiveTab] = useState(0); // Default to first tab

  return (
    <div className={styles.parentContainer}>
            <video className={styles.backgroundVideo} autoPlay loop muted>
        <source src={caseStudiesVideoBg} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={styles.secondContainer}>
      <FadeInOnScroll>
        <div className={styles.firstRowText}>
            <span className={styles.key}>Brand  </span>
            <span className={styles.pageTitle}>Chapter</span>
          </div>
      </FadeInOnScroll>
        <div className={styles.paneContainer}>
          <div className={styles.left}>
              {tabsData.map((tab, index) => (
                <div
                  key={index}
                  className={index === activeTab ? `${styles.leftTabs} ${styles.activeTab}` : styles.leftTabs}
                  onClick={() => setActiveTab(index)}
                >
                  {tab.title}
                </div>
              ))}
          </div>
          <div className={styles.right}>
            {/* <img src={tabsData[activeTab].icon} alt="icon" className="pb-6"/> */}
            <h1 className={styles.titleText}>
           {tabsData[activeTab].content}
            </h1>
            {/* ... other code ... */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;