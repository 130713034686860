import React from 'react'
import styles from './knowGood.module.scss'
import Section1 from '../../AllSections/knowGood/Section1/Section1'
import Section2 from '../../AllSections/knowGood/Section2/Section2'
import Section3 from '../../AllSections/knowGood/Section3/Section3'
import Section4 from '../../AllSections/knowGood/Section4/Section4'
import Section5 from '../../AllSections/knowGood/Section5/Section5'




function knowGood() {
  return (
    <div>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
    </div>
  )
}

export default knowGood