import React, { useState, useRef } from "react";
import styles from "./Section4.module.scss";
import girlPhoto from "../../../assets/culture/section4/GirlPhoto.jpg";
import chess from "../../../assets/caseStudies/chessPicture.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";

import {
  faPlay,
  faPause,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import Afrin from "../../../assets/testimonialVideos/AFRIN.mp4"
import Bhavana from "../../../assets/testimonialVideos/BHAVANA.mp4"
import Darthi from "../../../assets/testimonialVideos/DARTHI GOWDS HS.mp4"
import Hannah from "../../../assets/testimonialVideos/HANNAH.mp4"
import Maruthi from "../../../assets/testimonialVideos/MARUTHI.mp4"
import Nikhil from "../../../assets/testimonialVideos/NIKHIL.mp4"
import Sameer from "../../../assets/testimonialVideos/SAMEER.mp4"
import Sanjana from "../../../assets/testimonialVideos/SANJANA.mp4"

import AfrinThumbnail from "../../../assets/testimonialVideos/thumbnail/AFRIN.jpg"
import BhavanaThumbnail from "../../../assets/testimonialVideos/thumbnail/BHAVANA.jpg"
import DarthiThumbnail from "../../../assets/testimonialVideos/thumbnail/DHARTHI.jpg"
import HannahThumbnail from "../../../assets/testimonialVideos/thumbnail/HANNAH.jpg"
import MaruthiThumbnail from "../../../assets/testimonialVideos/thumbnail/MARUTHI.jpg"
import NikhilThumbnail from "../../../assets/testimonialVideos/thumbnail/NIKHIL.jpg"
import SameerThumbnail from "../../../assets/testimonialVideos/thumbnail//SAMEER.jpg"
import SanjanaThumbnail from "../../../assets/testimonialVideos/thumbnail/SANJANA.jpg"


// Sample Data
const cardData = [
  {
    name: "Bhavana",
    role: " Area Manager",
    review: "Freedom in Credvest",
    video: Bhavana,
    thumbnail: BhavanaThumbnail,
  },
  {
    name: "Maruthi",
    role: "Regional Head",
    review: "Mentorship’s in Credvest",
    video: Maruthi,
    thumbnail: MaruthiThumbnail,
  },
  {
    name: "Darthi Gowds HS",
    role: "Sr. Relationship Manager",
    review: "Collaboration in Credvest",
    video: Darthi,
    thumbnail: DarthiThumbnail,
  },
  {
    name: "Afrin",
    role: "Campaign Head",
    review: "Culture in Credvest",
    video: Afrin,
    thumbnail: AfrinThumbnail,
  },
  {
    name: "Hannah",
    role: "Chief People Officer",
    review: "Employee power in Credvest",
    video: Hannah,
    thumbnail: HannahThumbnail,

  },
  {
    name: "Nikhil",
    role: "Regional Head",
    review: "Process in Credvest",
    video: Nikhil,
    thumbnail: NikhilThumbnail,
  },
  {
    name: "Sameer",
    role: "Business Head",
    review: "Sky is the limit",
    video: Sameer,
    thumbnail: SameerThumbnail,
  },
  {
    name: "Sanjana",
    role: "Marketing Manager",
    review: "Learning in Credvest",
    video: Sanjana,
    thumbnail: SanjanaThumbnail,
  },
];

function Section4() {
  const [showModal, setShowModal] = useState(false);
  const [modalVideoSrc, setModalVideoSrc] = useState(null);
  const scrollRef = useRef(null);

  const handleScroll = (direction) => {
    const container = scrollRef.current;
    const scrollValue = direction === "left" ? -280 : 280;
    container.scrollBy({ left: scrollValue, behavior: 'smooth' });
  };

  const handleVideoPlay = (index) => {
    setModalVideoSrc(cardData[index].video);
    setShowModal(true);
  };

  return (
    <div className={styles.parentContainer}>
      <div className={styles.secondContainer}>
        <div className={styles.left}>
          <FadeInOnScroll>
            <div className={styles.titleGroup}>
              <span className={styles.coloredTitle}>I’m </span>
              <span className={styles.blackTitle}>Credvest</span>
            </div>
          </FadeInOnScroll>
        </div>
  
        <div className={styles.rightWrapper}>
          <div className={styles.arrowLeft} onClick={() => handleScroll("left")}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
  
          <div className={styles.right} ref={scrollRef}>
          {cardData.map((card, index) => (
    <div key={index} className={styles.card}>
        <div className={styles.videoContainer}>
            <video
                src={card.video}
                poster={card.thumbnail} // <-- Use the thumbnail property here
                style={{ width: "100%", height: "300px" }}
            ></video>
            <div className={styles.playButton} onClick={() => handleVideoPlay(index)}>
                <FontAwesomeIcon icon={faPlay} />
            </div>
        </div>

        <div className={styles.textContainer}>
            <h1 className={styles.nameText}>{card.name}</h1>
            <p className={styles.roleText}>{card.role}</p>
            <p className={styles.reviewText}>"{card.review}"</p>
        </div>
    </div>
))}
          </div>
  
          <div className={styles.arrowRight} onClick={() => handleScroll("right")}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>

        {showModal && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <video
                src={modalVideoSrc}
                controls
                autoPlay
               
              ></video>
              <button onClick={() => setShowModal(false)}>Close</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Section4;





