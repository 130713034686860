import React from 'react'
import styles from './Branding.module.scss'

import Section1 from '../../AllSections/Branding/Section1/Section1'
import Section2 from '../../AllSections/Branding/Section2/Section2'
import Section3 from '../../AllSections/Branding/Section3/Section3'
import Section4 from '../../AllSections/Branding/Section4/Section4'
import Section5 from '../../AllSections/Branding/Section5/Section5'





function Branding() {
  return (
    <div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />

    </div>
  )
}

export default Branding