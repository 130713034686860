import React from "react";
import styles from "./Section2.module.scss";
import CaseStudiesSec2Bg from "../../../assets/caseStudies/CaseStudiesSec2Bg";
import CaseStudiesSec2img from "../../../assets/caseStudies/CaseStudiesSec2img.webp";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section2() {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.absoluteImage}>
        <CaseStudiesSec2Bg imageSrc={CaseStudiesSec2img} />
      </div>
      <div className={styles.secondContainer}>
        <h1 className={styles.pageTitle}>
          {" "}
          <FadeInOnScroll>Marketing is the concrete of building blocks for selling</FadeInOnScroll>{" "}
        </h1>
        <p className={styles.textBody}>
            Effective marketing can make all the difference in achieving
            successful outcomes for both sellers and buyers. The real-estate
            industry marketing trends are drastically changing by the day, and
            now, you have to deal with all the repercussions. Seems unfair and
            undoable? We can take care of it, no problem! There are three pillars
            of marketing that Credvest builds its foundation on for successful
            sales in the Real Estate Industry
        </p>
      </div>
    </div>
  );
}

export default Section2;
