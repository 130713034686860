import React, { useEffect, useRef } from "react";
import styles from "./Section4.module.scss";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";
import Why from "../../../assets/home/why.webp";

function Section4() {
  const iframeRef = useRef(null);
  useEffect(() => {
    function handleResize(event) {
      const iframe = iframeRef.current;
      if (iframe && event.data.height) {
        iframe.style.height = `${event.data.height}px`;
      }
    }

    window.addEventListener("message", handleResize);
    return () => window.removeEventListener("message", handleResize);
  }, []);

  return (
    <div
      style={{ backgroundImage: `url('${Why}')`, backgroundSize: "cover" }}
      className={styles.homeSection + " py-40 bg-cover max-md:py-12"}
    >
      <div className={styles.secondContainer}>
        <div className={styles.TitleContainer}>
          <FadeInOnScroll>
            <h1 className={styles.title}>
              Why &nbsp;
              <span className={styles.successText + " text-primary"}>
                Credvest
              </span>
              ?
            </h1>
          </FadeInOnScroll>
        </div>
        <p className="px-[15%] max-md:px-12 text-white font-oxygen font-bold text-xl md:text-2xl md:px-[30%] max-md:w-full">
          Credvest&apos;s distinctive touch, backed by a Crisil Rating of BB+,
          ensures that our approach not only meets but exceeds expectations,
          delivering remarkable results in a highly competitive market.
        </p>
        {/* <div className={styles.paneContainer}>
          <div className={styles.left}>
            <h1 className={styles.heading}>
              Chasing Impossibilities. A Culture that’s Driven us, Above and
              Beyond
            </h1>
            <p className={styles.bodyText}>
              Culture has been a term that’s been loosely used over decades. In
              most cases to dish out a case of moral policing by shouting out “
              Our Culture does not allow us to..” So the question beckons, what
              does it allow us to do...
            </p>
            <div className={styles.profileContainer}>
              <div className={styles.profileImage}>
                <img
                  className={styles.blobMask}
                  src={user}
                  alt="Description of the image"
                />
              </div>
              <div className={styles.profileTextContainer}>
                <h2 className={styles.profileName}>Rajiv John</h2>
                <p className={styles.role}>Managing Director</p>
              </div>
            </div>
            <button
              className={"button"}
              onClick={() => navigate("/directorscut")}
            >
              <span className="">Read More</span>
              <i className="fa fa-arrow-right text-base"></i>
            </button>
          </div>
          <div className={styles.right}>
            <div className={styles.imageContainer}>
              <HomeSec4Mask imageSrc={image} />
            </div>
          </div>
        </div>  */}
      </div>
    </div>
  );
}

export default Section4;
