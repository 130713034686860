import React from 'react'
import styles from './Section1.module.scss'
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section1() {
  return (
    <div className={styles.parentContainer + " commonParentContainer "}>
      <div className={styles.secondContainer}>
        <h1 className={styles.knowGood}> Branding  </h1>
        <div class={styles.thinLine}></div>
        <h2 className={styles.areYou}>Perception is key. Mindset, the lock</h2>
      </div>
    </div>
  )
}

export default Section1