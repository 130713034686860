import React, { useState, useEffect, useRef } from "react";
import styles from "./Section3.module.scss";
import caseStudiesVideoBg from "../../../assets/caseStudies/Cofeee.mp4";
import AnalyzeIcon from "../../../assets/Branding/Section3/icons/Analyze.svg"
import BrandIcon from "../../../assets/Branding/Section3/icons/Brand.svg"
import ProductIcon from "../../../assets/Branding/Section3/icons/Product.svg"
import SentimentIcon from "../../../assets/Branding/Section3/icons/Sentiment.svg"
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";


function Section3() {
  const tabsData = [
    {
      title: "Initial Brand Positioning ",
      content: "Initial brand positioning refers to the process of defining and establishing the unique identity and value proposition. It involves identifying the target market, understanding customer needs and preferences, and developing a clear positioning statement that sets you apart from the competition. The brand positioning should encompass key elements that lay the foundation for all branding and marketing activities, guiding the website's messaging, design, and overall strategy.",
      icon: AnalyzeIcon // Replace with the corresponding icon for this tab
    },
    {
      title: "Analyzing Data",
      content: "Data analysis is crucial in the real estate industry as it provides valuable insights into market trends, customer behavior, and the performance of the website. By collecting and analyzing data from various sources, such as market trends, buyer behavior, and competition helps us gain valuable insights to make informed decisions and optimize operations. Data analysis can help identify patterns and trends in property demand, pricing, and location preferences, allowing data driven decisions to set realistic pricing, identify potential buyers, and develop effective marketing and sales strategies tailored to specific market conditions. ",
      icon: BrandIcon // Replace with the corresponding icon for this tab
    },
    {
      title: "Product Positioning",
      content: "Product positioning involves strategically positioning specific properties within the market to target a specific audience and meet their needs effectively. This process requires analyzing market trends, competition, and customer preferences to identify the unique selling points of a property or service. By highlighting the distinguishing features, benefits, and value proposition of the product, we can position it in a way that appeals to its target audience. For example, a property may be positioned as a luxury waterfront retreat, a family-friendly suburban home, or an affordable urban apartment. Effective product positioning helps us attract the right buyers or renters and maximize the property's market potential.",
      icon: ProductIcon // And so on...
    },
    {
      title: "Sentiment Analysis",
      content: "Product positioning involves strategically positioning specific properties within the market to target a specific audience and meet their needs effectively. This process requires analyzing market trends, competition, and customer preferences to identify the unique selling points of a property or service. By highlighting the distinguishing features, benefits, and value proposition of the product, we can position it in a way that appeals to its target audience. For example, a property may be positioned as a luxury waterfront retreat, a family-friendly suburban home, or an affordable urban apartment. Effective product positioning helps us attract the right buyers or renters and maximize the property's market potential.",
      icon: SentimentIcon // And so on...
    }
  ];

  const [activeTab, setActiveTab] = useState(0); // Default to first tab

  return (
    <div className={styles.parentContainer}>
            <video className={styles.backgroundVideo} autoPlay loop muted playsInline >
        <source src={caseStudiesVideoBg} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={styles.secondContainer}>
      <FadeInOnScroll>
        <div className={styles.firstRowText}>
            <span className={styles.key}>Brand  </span>
            <span className={styles.pageTitle}>Chapter</span>
          </div>
      </FadeInOnScroll>
        <div className={styles.paneContainer}>
          <div className={styles.left}>
            {tabsData.map((tab, index) => (
              <div 
                key={index}
                className={index === activeTab ? `${styles.leftTabs} ${styles.activeTab}` : styles.leftTabs}
                onClick={() => setActiveTab(index)}
              >
                {tab.title}
              </div>
            ))}
          </div>
          <div className={styles.right}>
            {/* <img src={tabsData[activeTab].icon} alt="icon" className="pb-7"/> */}
            <h1 className={styles.titleText}>
            {tabsData[activeTab].content}
            </h1>
            {/* ... other code ... */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;