import React from 'react'
import styles from './Marketing.module.scss'
import Section1 from '../../AllSections/Marketing/Section1/Section1'
import Section2 from '../../AllSections/Marketing/Section2/Section2'
import Section3 from '../../AllSections/Marketing/Section3/Section3'
import Section4 from '../../AllSections/Marketing/Section4/Section4'
import Section5 from '../../AllSections/Marketing/Section5/Section5'


function Marketing() {
  return (
    <div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />

    </div>
  )
}

export default Marketing